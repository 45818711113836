<script>
import RegionBase from './RegionBase'
import { japanManifest } from '../ea-vgas-giveaway-manifest'

export default {
  extends: RegionBase,
  data() {
    return {
      regionId: 'japan',
      activeIntro: 'Giveaways for Japan',
      gameList: japanManifest
    }
  },
  metaInfo() {
    return {
      title: 'The Game Awards Giveaways - Japan',
      titleTemplate: '%s | Surf Giveaways'
    }
  }
}
</script>
<style lang="scss" src="@/assets/themes/ea-vgas/scss/main.scss" />
